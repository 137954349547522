#faq-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: #101010;
    height: 100vh;
    padding-top: 8vh;
}

#faq-header-desktop{
    font-family: 'Inter', serif;
    color: var(--text);
    font-size: clamp(2.5rem, 5vw, 3rem);
}

.faq-products-container-desktop{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    color: var(--text);
    font-family: 'Inter', serif;
    height: 100vh;
    min-height: fit-content;
}

@media (max-width: 1800px) {
    .faq-products-container-desktop{
        width: calc(100% - 128px);
    }
}

.faq-product-container-desktop{
    background-color: var(--foreground-one);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
}

.faq-icon-desktop{
    width: 5%;
    margin-right: 32px;
    border-radius: 5px;
}

.faq-item-container-desktop{
    margin-block: 32px;
    margin-inline: 16px;
    padding-inline: 16px;
    padding-block: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: var(--foreground-one);
}

.faq-item-container-desktop h4{
    padding: 0;
    margin: 0;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1em, 2vw, 1.5rem);
}

.faq-item-container-desktop p, li{
    margin: 0;
    padding: 0;
    margin-top: 16px;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.8em, 2vw, 1rem);
}

.faq-item-container-desktop ol{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.8em, 2vw, 1rem);
}

/*////////////////   MOBILE STYLES   ////////////////*/

#faq-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: #101010;
    height: 100vh;
    padding-top: 8vh;
}

#faq-header-mobile{
    font-family: 'Inter', serif;
    color: var(--text);
    font-size: 1.5rem;
    text-align: center;
}

.faq-products-container-mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    color: var(--text);
    font-family: 'Inter', serif;
    height: 100vh;
}

.faq-product-container-mobile{
    background-color: var(--foreground-one);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
}

.faq-icon-mobile{
    width: 15%;
    margin-right: 16px;
    border-radius: 5px;
}

#drop-down-arrow-mobile{
    width: 10%;
}

.faq-item-container-mobile{
    margin-block: 32px;
    margin-inline: 8px;
    padding-inline: 16px;
    padding-block: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: var(--foreground-one);
}

.faq-item-container-mobile h4{
    padding: 0;
    margin: 0;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1em, 2vw, 1.5rem);
}

.faq-item-container-mobile p, li{
    margin: 0;
    padding: 0;
    margin-top: 16px;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.8em, 2vw, 1rem);
}

.faq-item-container-mobile ol{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.8em, 2vw, 1rem);
}