#error-body{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
}

#fake-nav-container{
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: calc(100% - 32px);
    max-height: 5%;
    margin-inline: 8px;
    margin-top: 8px;
    box-sizing: border-box;
}

#home-link-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: underline;
    margin: 0;
    padding: 0;
}

#icon-img{
    width: 5%;
    object-fit: contain;
    border-radius: 50px;
}

@media(max-width: 1800px){
    #icon-img{
        width: 10%;
    }
}

#icon-img-mobile{
    width: 25%;
    object-fit: contain;
}

#error-page{
    font-family: 'Inter', serif;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: var(--text);
}

#error-page h1{
    font-size: clamp(2rem, 8vw, 3rem);
    margin-bottom: 0;
    color: var(--text);
}

#error-page p{
    color: var(--text);
    font-size: 24px;
}

#header-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#errorScientist-img{
    width: 25%;
    aspect-ratio: 1;
    border-radius: 100px;
}