#mobile-nav-logo-mobile {
    width: 30%;
    object-fit: contain;
}

#mobile-nav-list-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #101010;
    padding-top: 8vh;
    z-index: 6;
    top: 0;
}

.mobile-nav-list-group-container {
    background-color: #101010;
    margin-block: 2%;
    margin-left: 5%;
}

.mobile-nav-list-group-container h3 {
    color: #508b68;
    font-size: clamp(1.25rem, 1.5vw, 3rem);
    font-weight: 700;
    font-family: 'Inter', serif;
}

.mobile-nav-list-group-container p {
    margin-left: 5%;
    font-size: clamp(1rem, 1.5vw, 2.75rem);
    font-weight: 400;
    font-family: 'Inter', serif;
    color: #F5F5F5;
}

.mobile-nav-list-group-container a {
    color: #F5F5F5;
}