:root {
  --background-color: #101010;
  --foreground-one: #202020;
  --foreground-two: #2D2D2D;
  --text: #F5F5F5;
  --button-primary: #F5F5F5;
  --font: 'Inter', serif;
  --gradient: linear-gradient(90deg, var(--background-color) 0%, #508b68 50%, var(--background-color) 100%);
  --alt-gradient: linear-gradient(90deg, rgba(80, 139, 104, 0.5) 0%,#508b68 10%, rgba(80, 139, 104, 0.5) 60%,  #508b68 75%, rgba(80, 139, 104, 0.5) 100%);
  --tert-gradient: linear-gradient(90deg, #A2BFAE 0%,#508b68 10%, #A2BFAE 45%,  #508b68 80%, #A2BFAE 100%);
  scrollbar-width: thin;
  scrollbar-color: #2D2D2D #101010;
}


body{
  overflow-x: hidden;
  background-color: #101010;
}

.App{
  background-color: #101010;
  font-family: 'Roboto', sans-serif;
}

.hidden-container{
  opacity: 0;
}

.fadeIn-anim{
  animation: fadeIn forwards 1s;
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.fadeIn-anim-up{
  animation: fadeInUp forwards 1s;
}

.fadeIn-anim-down{
  animation: fadeInDown forwards 1s;
}

.fadeIn-anim-left{
  animation: fadeInLeft forwards 1s;
}

.fadeIn-anim-right{
  animation: fadeInRight forwards 1s;
}

@keyframes fadeInUp{
  0%{
    opacity: 0;
    transform: translateY(10%)
  }
  100%{
    opacity: 1;
    transform: translateY(4%)
  }
}

@keyframes fadeInDown{
  0%{
    opacity: 0;
    transform: translateY(-10%)
  }
  100%{
    opacity: 1;
    transform: translateY(-4%)
  }
}

@keyframes fadeInleft{
  0%{
    opacity: 0;
    transform: translateX(10%)
  }
  100%{
    opacity: 1;
    transform: translateX(4%)
  }
}

@keyframes fadeInRight{
  0%{
    opacity: 0;
    transform: translateX(-10%)
  }
  100%{
    opacity: 1;
    transform: translateX(-4%)
  }
}

.gradient-text-green {
  background: var(--tert-gradient);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--font);
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
  animation: gradientShift 10s infinite linear;
}

.alt-gradient-text-green {
  background: var(--alt-gradient);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--font);
  text-shadow: 0px 0px 5px rgba(80, 139, 104, 0.5);
  animation: gradientShift 10s infinite linear;
}

#logo-link-container{
  width: 60%;
}

#loadingCover{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 110%;
  top: 0;
  background-color: #101010;
  z-index: 15;
}

#loading-cover-img{
  scale: 0.15;
}

#loading-cover-img-mobile{
  scale: 0.05;
}

#landing-planet-desktop{
  position: absolute;
  width: 100%;
  transform: translate(50%, -50%);
  z-index: 0;
}

#landing-stars-desktop{
  width: 90%;
  position: absolute;
  top: -10%;
  height: auto;
  z-index: 1;
  pointer-events: none;
  opacity: 1;
}

@media screen and (max-width: 1800px){
  #landing-stars-desktop{
    width: 100%;
    top: 5%;
  }
}

#landing-container-desktop{
  width: 100%;
  height: 100vh;
  background: radial-gradient(circle at 50% 100%, var(--foreground-one) 0%, rgba(16,16,16,1) 30%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: relative;
}

#landing-text-container-desktop{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60%;
  z-index: 5;
}

@media (max-width: 1920px){
  #landing-text-container-desktop{
    width: 80%;
  }
}

@media screen and (max-width: 1800px){
  #landing-text-container-desktop{
    width: calc(100% - 128px);
  }
}

#landing-text-container-desktop h1{
  font-family: 'Inter', sans-serif;
  font-size: clamp(2.5rem, 5vw, 5rem);
  color: #F5F5F5;
  padding: 0;
  font-weight: 700;
  margin: 0 0 32px 0;
  z-index: 5;
  text-align: center;
}

#landing-text-container-desktop img{
  width: 50%;
  object-fit: contain;
}

@media (max-width: 1920px){
  #landing-text-container-desktop img{
    width: 40%;
  }
}

@media screen and (max-width: 1800px){
  #landing-text-container-desktop img{
    width: 40%;
  }
}

#landing-text-container-desktop h3{
  font-family: 'Inter', sans-serif;
  font-size: clamp(1rem, 2vw, 2rem);
  color: #F5F5F5;
  padding: 0;
  font-weight: 400;
  margin: 0 0 64px 0;
}

#landing-button-container-desktop{
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 1800px){
  #landing-button-container-desktop{
    width: 45%;
  }
}


.primary-button{
  background-color: #F5F5F5;
  border-radius: 50px;
  color: #FFFFFF;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 1.5rem;
  border: none;
  padding: 8px 32px;
  cursor:pointer;
}

.primary-button p{
  margin: 0;
  padding: 0;
  background: var(--tert-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor:pointer;
}

.tertiary-button{
  text-decoration: none;
    border-radius: 50px;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    font-weight: 700;
    z-index: 10;
    width: fit-content;
    cursor: pointer;
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: 1.5rem;
    padding: 8px 32px;
    margin-right: 16px;
  }

.tertiary-button p{
  margin: 0;
  padding: 0;
  background: var(--alt-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#whatwedo{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

#whatwedo-container-desktop{
  background-color: var(--background-color);
  border: 1px solid rgba(255, 255, 255, 0.25);
  width: 60%;
  padding:  32px 64px;
  margin-top: -128px;
  box-sizing: border-box;
  position: relative;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 5;
}

@media screen and (max-width: 1800px){
  #whatwedo-container-desktop{
    width: calc(100% - 128px);
  }
}

#whatwedo-text-container-desktop{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#whatwedo-text-container-desktop h2{
  font-family: 'Inter', serif;
  font-size: clamp(1.5rem, 3vw, 4rem);
  color: var(--text);
  text-align: left;
  width: 100%;
}

#whatwedo-text-container-desktop p{
  font-family: 'Inter', serif;
  font-size: clamp(0.75rem, 1.5vw, 1.5rem);
  color: var(--text);
  text-align: left;
}

#checkmarks-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -18px;
  box-sizing: border-box;
}

#checkmarks-container div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-inline: 4px;
  box-sizing: border-box;
}

#checkmarks-container img{
  width: 25%;
  object-fit: contain;
  margin-right: 4px;
}

#whatwedo-graphic-container-desktop{
  width: 50%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1800px){
  #whatwedo-graphic-container-desktop{
    height: 400px;
  }
}

.landing-video-desktop{
  height: 100%;
  object-fit: contain;
}

#whatweoffer{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#whatweoffer-container-desktop{
  width: 60%;
  margin-top: 128px;
  box-sizing: border-box;
  position: relative;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@media screen and (max-width: 1800px){
  #whatweoffer-container-desktop{
    width: calc(100% - 128px);
  }
}

#whatweoffer-header-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#whatweoffer-header-container img{
  width: 20%;
  object-fit: contain;
}

#whatweoffer-header-container h2{
  font-family: 'Inter', serif;
  color: var(--text);
  font-size: clamp(1.5rem, 3vw, 4rem);
  padding: 0;
  margin: 0;
  margin-left: -16px;
}

#services-container-desktop{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-block: 16px;
  box-sizing: border-box;
}

#services-container-desktop div{
  margin-top: 64px;
  margin-inline: 16px;
  padding: 32px;
  border-radius: 10px;
  box-sizing: border-box;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 75%);
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
}

#services-container-desktop div img{
  width: 25%;
  margin-right: 32px;
  border-radius: 5px;
  object-fit: contain;
}

#services-container-desktop div h4{
  font-family: 'Inter', serif;
  font-size: clamp(0.75rem, 1.5vw, 1.5rem);
  color: var(--text);
  text-align: left;
  width: 90%;
  padding: 0;
  margin: 0;
  cursor:default;
}

#services-gradient-desktop{
  position: absolute;
  z-index: -1;
  top: -45%;
  min-width:180%;
}

#explore{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#explore-container-desktop{
  width: 60%;
  margin-top: 64px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  position: relative;
  padding-top: 64px;
  padding-inline: 64px;
  box-sizing: border-box;
  padding-bottom: 128px;
}

@media screen and (max-width: 1800px){
  #explore-container-desktop{
    width: 100%;
  }
}

#explore-container-desktop h2{
  width: 100%;
  font-family: 'Inter', serif;
  color: var(--text);
  font-size: clamp(1.5rem, 3vw, 4rem);
}

#explore-child-container-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding-block: 32px;
  position: relative;
  gap: 32px;
  scrollbar-width: none;
}

#explore-child-container-desktop::-webkit-scrollbar {
  display: none;
}

#explore-item-container-desktop{
  width: 40%;
  height: 460px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 16px;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-inline: 32px;
  scroll-snap-align: start;
  flex: 0 0 auto;
}

@media screen and (max-width: 1800px){
  #explore-item-container-desktop{
    height: 400px;
  }
}

#explore-item-container-desktop img{
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media screen and (max-width: 1800px){
  #explore-item-container-desktop img{
    height: 175px;
  }
}

#explore-item-container-desktop h4{
  font-family: 'Inter', serif;
  font-size: clamp(0.75rem, 1.5vw, 1.5rem);
  color: var(--text);
  font-weight: 700;
  padding: 0;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 8px;
}

#explore-item-container-desktop h5{
  font-family: 'Inter', serif;
  font-size: clamp(0.75rem, 1.25vw, 1.25rem);
  color: var(--text);
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
}

#explore-item-container-desktop p{
  font-family: 'Inter', serif;
  font-size: clamp(0.5rem, 1vw, 1rem);
  color: var(--text);
  font-weight: 400;
  margin: 0;
  padding: 0;
}

#explore-item-container-desktop button{
  margin-top: 16px;
  width: fit-content;
  position: relative;
  border: none;
  padding: 8px 32px;
  border-radius: 50px;
  align-self:flex-end;
  background-color: var(--button-primary);
  align-self: flex-end;
  cursor: pointer;
}

#explore-item-container-desktop button p{
  color: #508B68;
  font-family: 'Inter', serif;
  font-weight: 700;
}

.explore-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 24px;
}

.explore-prev {
  left: 8px;
}

.explore-next {
  right: 8px;
}

@media screen and (max-width: 768px) {
  .explore-nav-button {
    display: none;
  }
}

/*////////////////////////////  Mobile Styling  /////////////////////////////*/

.nav-mobile{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 8vh;
  box-sizing: border-box;
  padding-inline: 8px;
  padding-block: 16px;
  background-color: #101010;
  position: fixed;
  z-index: 10;
}

.mobile-shop-button{
  background-color: #508B68;
  border-radius: 50px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 4vw;
  margin-right: 5%;
  padding-block: 2%;
}

#hamburger-icon-mobile{
  color: #F5F5F5;
}

#landing-planet-mobile{
  position: absolute;
  width: 100%;
  transform: translate(50%, -50%);
  z-index: 0;
}

#landing-stars-mobile{
  width: 200%;
  position: absolute;
  top: 10%;
  height: auto;
  z-index: 1;
  pointer-events: none;
  opacity: 1;
}

#landing-container-mobile{
  width: 100%;
  height: 100vh;
  background: radial-gradient(circle at 50% 100%, var(--foreground-one) 0%, rgba(16,16,16,1) 30%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: relative;
}

#landing-text-container-mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90%;
  margin-top: 64px;
  z-index: 5;
}

#landing-text-container-mobile h1{
  font-family: 'Inter', sans-serif;
  font-size: clamp(2rem, 4vw, 4rem);
  color: #F5F5F5;
  padding: 0;
  font-weight: 700;
  margin: 0 0 32px 0;
  z-index: 5;
  text-align: center;
}

#landing-text-container-mobile img{
  width: 70%;
  object-fit: contain;
}

#landing-text-container-mobile h3{
  font-family: 'Inter', sans-serif;
  font-size: clamp(1rem, 2vw, 2rem);
  color: #F5F5F5;
  padding: 0;
  font-weight: 400;
  margin: 0 0 64px 0;
  text-align: center;
}

#landing-button-container-mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.primary-button-mobile{
  background-color: #F5F5F5;
  border-radius: 50px;
  color: #FFFFFF;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 1.25em;
  border: none;
  padding: 16px 32px;
  cursor:pointer;
}

.primary-button-mobile p{
  margin: 0;
  padding: 0;
  background: var(--tert-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor:pointer;
}

.tertiary-button-mobile{
  text-decoration: none;
  border-radius: 50px;
  border: 1px solid transparent;
  background:
      linear-gradient(to right, rgba(16, 16, 16, 1), rgba(16, 16, 16, 1)),
      var(--alt-gradient);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  font-weight: 700;
  z-index: 10;
  width: fit-content;
  cursor: pointer;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 16px 32px;
  margin-bottom: 16px;
  width: 100%;
}

.tertiary-button-mobile p{
  margin: 0;
  padding: 0;
  background: var(--alt-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#whatwedo-container-mobile{
  background-color: var(--background-color);
  border: 1px solid rgba(255, 255, 255, 0.25);
  width: 90%;
  padding:  16px 32px;
  box-sizing: border-box;
  position: relative;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
}

#whatwedo-text-container-mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#whatwedo-text-container-mobile h2{
  font-family: 'Inter', serif;
  font-size: clamp(2.0rem, 3.5vw, 4.5rem);
  color: var(--text);
  text-align: center;
  width: 100%;
}

#whatwedo-text-container-mobile p{
  font-family: 'Inter', serif;
  font-size: clamp(1.0rem, 1.75vw, 1.75rem);
  color: var(--text);
  text-align: center;
}

#checkmarks-container-mobile{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

#checkmarks-container-mobile div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-inline: 4px;
  width: 45%;
  box-sizing: border-box;
}

#checkmarks-container-mobile img{
  width: 25%;
  object-fit: contain;
  margin-right: 4px;
}

#whatwedo-graphic-container-mobile{
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-video-mobile{
  height: 100%;
  object-fit: contain;
}

#whatweoffer-container-mobile{
  width: 90%;
  margin-top: 128px;
  box-sizing: border-box;
  position: relative;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#whatweoffer-header-container-mobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#whatweoffer-header-container-mobile img{
  width: 45%;
  object-fit: contain;
}

#whatweoffer-header-container-mobile h2{
  font-family: 'Inter', serif;
  color: var(--text);
  font-size: clamp(2.0rem, 3.5vw, 4.5rem);
  padding: 0;
  margin: 0;
  margin-left: -8px;
}

#services-container-mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-block: 8px;
  box-sizing: border-box;
}

#services-container-mobile div{
  margin-top: 32px;
  padding: 32px;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 75%);
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
}

#services-container-mobile div img{
  width: 20%;
  margin-right: 32px;
  border-radius: 5px;
  object-fit: contain;
}

#services-container-mobile div h4{
  font-family: 'Inter', serif;
  font-size: clamp(1.0rem, 1.25vw, 2.25rem);
  color: var(--text);
  text-align: left;
  width: 90%;
  padding: 0;
  margin: 0;
}

#services-gradient-mobile{
  position: absolute;
  z-index: -1;
  top: 0%;
  transform: rotate(90deg);
  min-width:160%;
}

#explore-container-mobile{
  width: 90%;
  margin-top: 64px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  position: relative;
  padding-top: 64px;
  box-sizing: border-box;
  padding-bottom: 128px;
}

#explore-container-mobile h2{
  width: 100%;
  font-family: 'Inter', serif;
  color: var(--text);
  font-size: clamp(2.0rem, 3.5vw, 4.5rem);
  text-align: center;
}

#explore-child-container-mobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding-block: 32px;
  position: relative;
  scrollbar-width: none;
}

#explore-item-container-mobile{
  width: 100%;
  height: 475px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 16px;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-inline: 32px;
  scroll-snap-align: start;
  flex: 0 0 auto;
}

#explore-item-container-mobile img{
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#explore-item-container-mobile h4{
  font-family: 'Inter', serif;
  font-size: clamp(1.0rem, 1.25vw, 2.25rem);
  color: var(--text);
  font-weight: 700;
  padding: 0;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 8px;
}

#explore-item-container-mobile h5{
  font-family: 'Inter', serif;
  font-size: clamp(1.0rem, 1.15vw, 1.15rem);
  color: var(--text);
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
}

#explore-item-container-mobile p{
  font-family: 'Inter', serif;
  font-size: 1.0rem;
  color: var(--text);
  font-weight: 400;
  margin: 0;
  padding: 0;
}

#explore-item-container-mobile button{
  margin-top: 16px;
  width: fit-content;
  position: relative;
  border: none;
  padding: 8px 32px;
  border-radius: 50px;
  align-self:flex-end;
  background-color: var(--button-primary);
  align-self: flex-end;
  cursor: pointer;
}

#explore-item-container-mobile button p{
  color: #508B68;
  font-family: 'Inter', serif;
  font-weight: 700;
}