#create-account-main-container-desktop{
    display: flex;
    flex: 1;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #F2F4F3;
}

#create-account-main-container-desktop form{
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 30%;
    background-color: #F2F4F3;
    box-shadow: 0px 5px 25px 1px rgba(0, 0, 0, .15);
    padding: 2%;
    border-radius: 15px;
}

#create-account-main-container-desktop input{
    padding-block: 2%;
    font-size:0.9vw;
}

#create-account-main-container-desktop label{
    font-size: 1vw;
}

#create-account-main-container-desktop h3{
    margin: 0;
    margin-bottom: 5%;
    align-self: center;
}

#create-account-main-container-desktop button{
    border: none;
    background-color: #508B68;
    padding-block: 1%;
    border-radius: 15px;
    width: 26.5%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 0.9vw;
    margin-top: 1%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.password-input-container-desktop img{
    right: 2%;
    top: 30%;
}

.createAccountloader{
    border: 5px solid #f3f3f3;
    border-top: 5px solid #508B68; 
    border-radius: 50%; 
    width: 20px; 
    height: 20px; 
    animation: spin 2s linear infinite; 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/*//////    MOBILE STYLES    ////////*/

#create-account-main-container-mobile{
    display: flex;
    flex: 1;
    height: 100dvh;
    width: 100%;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
    background-color: #F2F4F3;
}

#create-account-main-container-mobile form{
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 80%;
    background-color: #F2F4F3;
    box-shadow: 0px 5px 25px 1px rgba(0, 0, 0, .15);
    padding: 5%;
    border-radius: 15px;
    box-sizing: border-box;
    margin-top: 8vh;
}

#create-account-main-container-mobile input{
    padding-block: 2%;
    font-size: 4vw;
}

#create-account-main-container-mobile label{
    font-size: 4.5vw;
}

#create-account-main-container-mobile h3{
    margin: 0;
    margin-bottom: 5%;
    align-self: center;
}

#create-account-main-container-mobile button{
    border: none;
    background-color: #508B68;
    padding-block: 2%;
    border-radius: 10px;
    width: 80%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 4vw;
    margin-top: 2%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.password-input-container-mobile img{
    right: 2%;
    top: 30%;
}

.createAccountloader{
    border: 5px solid #f3f3f3;
    border-top: 5px solid #508B68; 
    border-radius: 50%; 
    width: 20px; 
    height: 20px; 
    animation: spin 2s linear infinite; 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}