#ipw-purchase-window-main {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    z-index: 25;
    backdrop-filter: blur(10px);
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
}

#ipw-close-icon-desktop {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 2%;
    object-fit: contain;
    cursor: pointer;
}

@media(max-width: 1800px) {
    #ipw-item-purchase-container-desktop {
        width: 2%;
        top: 2%;
        right: 2%;
    }
}

#ipw-item-purchase-container-desktop {
    width: 60%;
    margin-left: 64px;
    margin-right: 64px;
    max-height: 90%;
    overflow: hidden;
    padding: 32px;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-sizing: border-box;
    background-color: var(--background-color);
}

@media(max-width: 1800px) {
    #ipw-item-purchase-container-desktop {
        width: calc(100% - 128px);
    }
}

#ipw-header-container-desktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ipw-header-container-desktop img {
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
}

#ipw-header-choice-container-desktop {
    width: 100%;
    font-family: 'Inter', serif;
    color: var(--text);
    font-weight: 400;
}

#ipw-header-choice-container-desktop h1 {
    font-weight: 700;
    font-size: clamp(2.5rem, 1.5vw, 4.5rem);
}

#ipw-header-choice-container-desktop p {
    font-size: clamp(0.75rem, 1.5vw, 1.75rem);
}

#ipw-price-selection-container-desktop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.ipw-select-style {
    width: 40%;
    background-color: #202020;
    border: none;
    padding-inline: 15px;
    padding-block: 20px;
    font-family: 'Inter', serif;
    font-weight: 400;
    color: var(--text);
    font-size: clamp(1.75rem, 1.5vw, 2rem);
    border-radius: 5px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.78 66.39"><path d="M56.39,66.39c-2.56,0-5.12-.98-7.07-2.93L2.93,17.07C-.98,13.17-.98,6.83,2.93,2.93c3.91-3.91,10.24-3.91,14.14,0l39.32,39.32L95.71,2.93c3.91-3.91,10.24-3.91,14.14,0,3.91,3.91,3.91,10.24,0,14.14l-46.39,46.39c-1.95,1.95-4.51,2.93-7.07,2.93Z" fill="%232d2d2d"/></svg>');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-size: 8%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ipw-select-style::-ms-expand {
    display: none;
}

#ipw-quantity-container-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 30%;
}

#ipw-quantity-container-desktop button {
    justify-content: center;
    align-items: center;
}

#ipw-quantity-container-desktop button p {
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(2.75rem, 1.5vw, 3.5rem);
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
}

#ipw-quantity-container-desktop input {
    background-color: #202020;
    border: none;
    color: var(--text);
    padding-inline: 15px;
    padding-block: 10px;
    height: 100%;
    width: 10%;
    border-radius: 5px;
    text-align: center;
    font-size: clamp(1.75rem, 1.5vw, 2rem);
}

#ipw-quantity-container-desktop p {
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(1.75rem, 1.5vw, 2rem);
    padding: 0;
    margin: 0;
}

#ipw-add-button-desktop {
    width: 20%;
    all: unset;
    background: linear-gradient(90deg, #508b68 0%, #A2BFAE 50%, #508B68 100%);
    border-radius: 50px;
    padding-inline: 24px;
    padding-block: 8px;
    background-size: 200%;
    background-position: -75%;
    cursor: pointer;
    animation: gradientShift 10s infinite linear;
}

@keyframes gradientShift {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 200% 0%;
    }
}

#ipw-add-button-desktop p {
    padding: 0;
    margin: 0;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1rem, 1.5vw, 2rem);
}

#ipw-details-container-desktop {
    overflow-y: scroll;
    max-height: 35%;
    margin-top: 16px;
    padding-inline: 8px;
}

@media(max-width: 1800px) {
    #ipw-details-container-desktop {
        max-height: 45%;
    }
}

#ipw-details-container-desktop h3 {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(2rem, 1.5vw, 3rem);
}

#ipw-details-container-desktop p, h4, ol, li, ul {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.75rem, 1.5vw, 1.5rem);
}

#ipw-details-container-desktop li{
    margin-block: 8px;
}

#ipw-details-container-desktop ol, ul{
    margin-block: 16px;
}

#ipw-try-clashmarker-container {
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

#ipw-try-clashmarker-container h3 {
    margin: 0;
    padding: 0;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1rem, 1.5vw, 2rem);
    color: var(--text);
    width: 70%;
}

#ipw-try-clashmarker-container button {
    border: 1px solid transparent;
    border-radius: 50px;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    margin-left: 16px;
    text-decoration: none;
    cursor: pointer;
}

#ipw-try-clashmarker-container button p {
    margin: 0;
    padding-inline: 16px;
    padding-block: 8px;
}

#ipw-try-clashmarker-container p {
    font-family: 'Inter', serif;
    color: #FFFFFF;
    font-size: clamp(0.5rem, 1.5vw, 2rem);
    padding-block: 5%;
    margin: 0;
}

#ipw-try-clashmarker-container div {
    flex-direction: row;
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

/*//////////// MOBILE STYLES ////////////////*/

#ipw-close-icon-mobile {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 10%;
    object-fit: contain;
}

#ipw-item-purchase-container-mobile {
    width: 90%;
    max-height: 80%;
    overflow: hidden;
    padding: 32px;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-sizing: border-box;
    background-color: var(--background-color);
    overflow-y: scroll;
}

#ipw-header-container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ipw-header-container-mobile img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

#ipw-header-choice-container-mobile {
    width: 100%;
    font-family: 'Inter', serif;
    color: var(--text);
    font-weight: 400;
}

#ipw-header-choice-container-mobile h1 {
    font-weight: 700;
    font-size: clamp(1.75rem, 1.5vw, 3.75rem);
}

#ipw-header-choice-container-mobile p {
    font-size: clamp(1rem, 1.5vw, 2rem);
}

#ipw-price-selection-container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.ipw-select-style-mobile {
    width: 100%;
    background-color: #202020;
    border: none;
    padding-inline: 15px;
    padding-block: 20px;
    font-family: 'Inter', serif;
    font-weight: 400;
    color: var(--text);
    font-size: clamp(1.75rem, 1.5vw, 2rem);
    border-radius: 5px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.78 66.39"><path d="M56.39,66.39c-2.56,0-5.12-.98-7.07-2.93L2.93,17.07C-.98,13.17-.98,6.83,2.93,2.93c3.91-3.91,10.24-3.91,14.14,0l39.32,39.32L95.71,2.93c3.91-3.91,10.24-3.91,14.14,0,3.91,3.91,3.91,10.24,0,14.14l-46.39,46.39c-1.95,1.95-4.51,2.93-7.07,2.93Z" fill="%232d2d2d"/></svg>');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-size: 8%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 16px;
}

.ipw-select-style::-ms-expand {
    display: none;
}

#ipw-quantity-container-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
}

#ipw-quantity-container-mobile button {
    justify-content: center;
    align-items: center;
}

#ipw-quantity-container-mobile button p {
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(2.75rem, 1.5vw, 3.5rem);
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
}

#ipw-quantity-container-mobile input {
    background-color: #202020;
    border: none;
    color: var(--text);
    padding-inline: 15px;
    padding-block: 10px;
    height: 100%;
    width: 10%;
    border-radius: 5px;
    text-align: center;
    font-size: clamp(1.75rem, 1.5vw, 2rem);
}

#ipw-quantity-container-mobile p {
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(1.75rem, 1.5vw, 2rem);
    padding: 0;
    margin: 0;
}

#ipw-add-button-mobile {
    all: unset;
    background: linear-gradient(90deg, #508b68 0%, #A2BFAE 50%, #508B68 100%);
    border-radius: 50px;
    padding-inline: 32px;
    padding-block: 12px;
    background-size: 200%;
    background-position: -75%;
    cursor: pointer;
    animation: gradientShift 10s infinite linear;
}

#ipw-add-button-mobile p {
    padding: 0;
    margin: 0;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1.5rem, 1.5vw, 2.5rem);
    text-align: center;
}

#ipw-details-container-mobile {
    margin-top: 16px;
}

#ipw-details-container-mobile h3 {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1.5rem, 1.5vw, 2.5rem);
}

#ipw-details-container-mobile p, h4, ol, li, ul {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(1rem, 1.5vw, 1.75rem);
}

#ipw-details-container-mobile li{
    margin-block: 8px;
}

#ipw-details-container-mobile ol, ul{
    margin-block: 16px;
}

#ipw-try-clashmarker-container-mobile {
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 64px;
}

#ipw-try-clashmarker-container-mobile h3 {
    margin: 0;
    padding: 0 0 16px 0;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1rem, 1.5vw, 2rem);
    color: var(--text);
    width: 100%;
    text-align: center;
}

#ipw-try-clashmarker-container-mobile button {
    border: 1px solid transparent;
    border-radius: 50px;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
}

#ipw-try-clashmarker-container-mobile button p {
    margin: 0;
    padding-inline: 16px;
    padding-block: 16px;
}

#ipw-try-clashmarker-container-mobile p {
    font-family: 'Inter', serif;
    color: #FFFFFF;
    font-size: clamp(0.75rem, 1.5vw, 2.5rem);
    padding-block: 5%;
    margin: 0;
}