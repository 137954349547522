#productitem-container-desktop{
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    max-width: 30%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 15px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.upperHalf-container-desktop{
    width: 100%;
    height: 65%;
    overflow: hidden;
}

.upperHalf-container-desktop img{
    width: 100%;
}

.lowerHalf-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0% 5% 5% 5%;
}

.lowerHalf-container-desktop h3{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(1rem, 1.5vw, 2rem);
    margin-bottom: 0;
    color: var(--text);
    cursor:default;
}

.lowerHalf-container-desktop p{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1.5vw, 1rem);
    margin-bottom: 0;
    color: var(--text);
    cursor:default;
}

.lowerHalf-container-desktop img{
    width: 12%;
    cursor: pointer;
    align-self: flex-end;
}

.lowerHalf-container-desktop div{
    display: flex;
    flex-direction: row;
    flex: 1;
}

.lowerHalf-container-desktop button{
    all: unset;
    background-color: var(--button-primary);
    align-items: center;
    justify-content: center;
    padding-block: 8px;
    border-radius: 50px;
    margin-top: 16px;
    width: 50%;
    align-self: flex-end;
    cursor: pointer;
}



.lowerHalf-container-desktop button p{
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 700;
    background: var(--tert-gradient);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font);
    cursor: pointer;
}

/*MOBILE STYLES*/

#productitem-container-mobile{
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    width: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.upperHalf-container-mobile{
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.upperHalf-container-mobile img{
    width: 100%;
}

.lowerHalf-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0% 5% 5% 5%;
}

.lowerHalf-container-mobile h3{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(1.5rem, 1.5vw, 2.5rem);
    margin-bottom: 0;
    color: var(--text);
    cursor:default;
}

.lowerHalf-container-mobile p{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(1rem, 1.5vw, 1.75rem);
    margin-bottom: 0;
    color: var(--text);
    cursor:default;
}

.lowerHalf-container-mobile img{
    width: 12%;
    cursor: pointer;
    align-self: flex-end;
}

.lowerHalf-container-mobile div{
    display: flex;
    flex-direction: row;
    flex: 1;
}

.lowerHalf-container-mobile button{
    all: unset;
    background-color: var(--button-primary);
    align-items: center;
    justify-content: center;
    padding-block: 8px;
    border-radius: 50px;
    margin-top: 16px;
    width: 50%;
    align-self: flex-end;
    cursor: pointer;
}

.lowerHalf-container-mobile button p{
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 700;
    background: var(--tert-gradient);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font);
    cursor: pointer;
}