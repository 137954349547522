#main-nav-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #101010;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 10;
    position: fixed;
    top: 0;
    width: 80%;
    height: 6%;
    padding-inline: 16px;
    box-sizing: border-box;
    padding-block: 8px;
    border-radius: 0px 0px 25px 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    border-top: 0px;
    align-self: center;
}

@media(max-width: 1800px) {
    #main-nav-bar-container{
        width: calc(100% - 64px);
        margin: 0 32px 0 32px;
    }
}

#main-nav-bar-logo {
    max-width: 250px;
}

#main-nav-bar-links-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: var(--text);
}

#main-nav-bar-links-container * {
    margin-left: 8px;
    margin-right: 8px;
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    font-family: var(--font);
    font-weight: 400;
    color: var(--text);
    text-decoration: none;
    padding: 0;
    margin-block: 0;
}

.link-hover{
    transition: transform 1s;
}

.link-hover:hover{
    transform: translateY(-5px);
}

#main-nav-bar-contact-button {
    border: 1px solid transparent;
    border-radius: 50px;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    margin-left: 16px;
    margin-right: 16px;
    text-decoration: none;
    transition: border-color 1s;
    font-family: 'Inter', serif;
}

#main-nav-bar-contact-button:hover{
    border-color: #A2BFAE;
}

#main-nav-bar-contact-button p {
    margin: 0;
    padding: 4px 16px;
    color: var(--text);
    font-family: var(--font);
    font-weight: 400;
}

#main-nav-bar-shop-button {
    all: unset;
    background-color: #F5F5F5;
    border-radius: 50px;
    text-decoration: none;
    pointer-events: all;
    cursor: pointer;
    transition: opacity 1s;
}

#main-nav-bar-shop-button:hover{
    opacity: 0.75;
}

#main-nav-bar-shop-button p {
    margin: 0;
    padding: 4px 32px;
    font-weight: 700;
    background: var(--alt-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font);
    font-weight: 700;
}