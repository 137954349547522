#successIcon-img{
    width: 80%;
}

#error-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    margin-top: 64px;
}

#form-desktop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-top: 2%;
    flex-wrap: wrap;
}

#form-desktop label{
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(0.8vw, 1.5vw, 2vw);
    margin-right: 8px;
}

#form-desktop input{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 0.8vw;
    background-color: #202020;
    border: none;
    padding: 8px 16px;
    margin-right: 16px;
    border-radius: 5px;
    color: var(--text);
}

#form-desktop button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: clamp(0.8vw, 1.5vw, 2vw);
    padding: 8px 16px;
    text-align: center;
    border-radius: 50px;
    border: none;
    background-color: #F5F5F5;
    color: #508B68;
}

/*      MOBILE STYLES       */

#form-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 25%;
    width: 75%;
    justify-content: cemter;
    align-items: center;
    margin-top: 10%;
}

#form-mobile label{
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: clamp(3vw, 4vw, 5vw);
    color: var(--text);
    margin-bottom: 8px;
}

#form-mobile input{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(3vw, 4vw, 5vw);
    color: var(--text);
    background-color: #202020;
    border: none;
    margin: 8px 16px;
}

#form-mobile button{
    margin-top: 5%;
    padding: 8px 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: clamp(3vw, 4vw, 5vw);
    text-align: center;
    border-radius: 50px;
    border: none;
    background-color: #F5F5F5;
    color: #508B68;
}