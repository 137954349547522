.legal-parent-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: #101010;
    height: 100vh;
    padding-top: 8vh;
}

#legal-header-desktop{
    font-family: 'Inter', serif;
    color: var(--text);
    font-size: clamp(2.5rem, 5vw, 3rem);
}

#legal-docs-selector-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    width: 60%;
    justify-content: center;
    max-height: 4%;
}

@media (max-width: 1800px) {
    #legal-docs-selector-desktop{
        width: 80%;
    }
}

.legal-title-container-desktop{
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    margin-inline: 16px;
}

.legal-title-desktop{
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(0.5rem, 1.25vw, 1.5rem);
    position: relative;
    text-align: center;
    z-index: 2;
    height: 100%;
    color: var(--text);
    cursor: pointer;
}

.gradient-container{
    background: linear-gradient(to top, rgba(80, 139, 104, 0.75) -50%, rgba(0,0,0, 0.25) 100%);
    border-bottom: 3px solid #508b68;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-bottom: 5;
}

#legal-document-container-desktop{
    width: 60%;
    max-height: fit-content;
    margin-top: 2%;
}

#document-holder{
    background-color: #F5F5F5;
    border-radius: 5px;
    margin-bottom: 64px;
}

@media (max-width: 1800px) {
    #legal-document-container-desktop{
        width: 75%;
    }
}
/*************      MOBILE STYLES       ***************/

.legal-parent-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: #101010;
    height: 100vh;
    padding-top: 8vh;
}

#legal-header-mobile{
    font-family: 'Inter', serif;
    color: var(--text);
    font-size: 1.5rem;
}

#legal-docs-selector-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    width: 90%;
    justify-content: center;
}

.legal-title-container-mobile{
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    margin-inline: 8px;
}

.legal-title-mobile{
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: 0.8rem;
    position: relative;
    text-align: center;
    z-index: 2;
    height: 100%;
    cursor: pointer;
    color: var(--text);
}

#legal-document-container-mobile{
    width: 95%;
    max-height: fit-content;
    margin-top: 2%;
}