#banner-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    border-radius: 25px;
    margin-block: 5%;
    padding: 5%;
    background-color: #202020;
}

#banner-container-desktop img{
    width: 15%;
    object-fit: cover;
    border-radius: 50px;
}

#name-email-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    margin-left: 2%;
}

#name-email-container-desktop p{
    font-family: 'Inter', serif;
    font-size: clamp(0.25rem, 1.5vw, 1rem);
    margin-bottom: 1%;
    margin-top: 1%;
    color: var(--text);
}

#banner-container-desktop button{
    color: #508B68;
    border-radius: 50px;
    font-family: 'Roboto', sans-serif;
    font-size: clamp(0.25rem, 1.5vw, 1rem);
    padding-inline: 5%;
    padding-block: 2%;
    cursor: pointer;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #202020, #202020),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

#banner-container-desktop button p{
    padding: 0;
    margin: 0;
    font-family: 'Inter', serif;
    font-weight: 400;
}

/*//////////////////    MOBILE STYLES   ///////////////////*/

#banner-container-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    border-radius: 25px;
    margin-block: 5%;
    padding: 5%;
    margin-bottom: 10%;
    background-color: #202020;
}

#banner-container-mobile img{
    width: 20%;
    object-fit: cover;
    border-radius: 50px;
}

#name-email-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    margin-left: 2%;
    text-decoration: none;
}

#name-email-container-mobile p{
    font-family: 'Inter', serif;
    font-size: 3.5vw;
    margin-bottom: 0%;
    margin-top: 0%;
    color: var(--text);
    text-decoration: none;
}

#banner-container-mobile button{
    border-radius: 50px;
    font-size: clamp(0.75rem, 1.5vw, 1rem);
    padding-inline: 5%;
    padding-block: 4%;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #202020, #202020),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

#banner-container-mobile button p {
    padding: 0;
    margin: 0;
    color: #508B68;
    font-family: 'Roboto', sans-serif;
}