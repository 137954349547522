#cout-main-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    height: 100vh;
    justify-content: flex-start;
    align-items: center;
    background-color: #101010;
}

#nav-logo-desktop img{
    width: 10%;
    margin-top: 16px;
}

@media(max-width: 1800px) {
    #nav-logo-desktop img {
        width: 20%;
    }
}

#nav-logo-mobile{
    width: 40%;
    padding-left: 8px;
    padding-top: 8px;
}

#logged-in-modal-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    position: absolute;
    top: 1%;
    background-color: #202020;
    z-index: 15;
    align-self: center;
    left: 45%;
    right: 45%;
    width: 10%;
    border-radius: 15px;
    padding-inline: 2%;
    justify-content: center;
    align-items: center;
    animation: fadeInOut 7s both;
    opacity: 0;
}

#logged-in-modal-desktop p{
    font-family: 'Roboto', sans-serif;
    font-size: 0.75vw;
    font-weight: 500;
}

#logged-in-modal-desktop img{
    max-width: 10%;
    object-fit: contain;
    margin-left: 5%;
}

@keyframes fadeInOut{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

#cout-checkout-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 2;
    flex-wrap: wrap;
    width: 60%;
    max-height: 80%;
    align-self: center;
    justify-content: center;
    align-items: center;
    padding-top: 6vh;
}

@media(max-width: 1800px) {
    #cout-checkout-container-desktop {
        width: calc(100% - 128px);
    }
}

#cout-info-side-container-desktop{
    background-color: #101010;
    flex: 100%;
    padding-right: 5%;
    width: 50%;
    position: relative;
    box-sizing: border-box;
}

#cout-info-side-container-desktop h2{
    font-family: 'Inter', serif;
    font-weight: 700;
    padding: 0;
    margin: 32px 0 32px 0;
    font-size: clamp(2rem, 1vw, 3.5rem);
    color: #508B68;
}

#cout-info-side-container-desktop h3{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(1rem, 1vw, 2.5rem);
    padding: 0;
    margin: 0;
    margin-top: 2%;
    color: var(--text);
}

#cout-info-side-container-desktop form{
    margin-left: 2%;
    position: relative;
}

#cout-info-side-container-desktop h4{
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1rem, 1vw, 2.5rem);
    text-align: center;
    color: var(--text);
    padding: 0;
    margin: 0;
}

#cout-total-side-container-desktop{
    background-color: #101010;
    flex: 100%;
    border-left: solid 1pt #FFFFFF;
    padding-left: 5%;
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
}

#cout-total-side-container-desktop h2{
    font-family: 'Inter', serif;
    font-weight: 700;
    padding: 0;
    margin: 32px 0 32px 0;
    font-size: clamp(2rem, 1vw, 3.5rem);
    color: var(--text);
}

#cout-items-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
}

#cout-items-total-container-desktop{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 15%;
}

#cout-items-container-desktop h3{
    font-family: 'Inter', serif;
    font-size: clamp(1.5rem, 1vw, 3rem);
    color: var(--text);
    font-weight: 400;
}

.cout-totals-line-container-desktop{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.cout-totals-line-container-desktop h3{
    font-family: 'Inter', serif;
    font-size: clamp(1.5rem, 1vw, 3rem);
    color: var(--text);
}

.cout-totals-line-container-desktop h4{
    font-family: 'Inter', serif;
    font-size: clamp(0.5rem, 1.25vw, 1.25rem);
    color: var(--text);
}

.cout-totals-line-container-desktop p{
    font-family: 'Inter', serif;
    color: var(--text);
    font-size: clamp(0.25rem, 1.25vw, 1.25rem);
}

#cout-pay-total-button-desktop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    background-color: #508B68;
    border: none;
    position: absolute;
    bottom: 0;
    align-self: center;
    color: #FFFFFF;
    border-radius: 50px;
    padding-block: 16px;
    cursor: pointer;
}

#cout-pay-total-button-desktop p{
    font-family: 'Inter', serif;
    font-weight: 700;
    padding: 0;
    margin: 0;
    font-size: clamp(1rem, 1vw, 2rem);
}

#cout-form-names-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
}

#cout-form-names-container-desktop div{
    max-width: 50%;
}

.cout-form-item-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin-block: 2%;
}

.cout-form-item-container-desktop label{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.5rem, 1.25vw, 1.25rem);
    margin-block: 1%;
    color: var(--text);
}

.cout-form-item-container-desktop input{
    border: none;
    background-color: #2D2D2D;
    padding: 5px;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.5rem, 1.25vw, 1.25rem);
    border-radius: 5px;
    color: var(--text);
    padding-inline: 16px;
    margin-inline: 4px;
}

.cout-password-input-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    position: relative;
    height: auto;
}

.cout-password-input-container-desktop input{
    width: 100%;
    height: 100%;
    padding-inline: 16px;
    margin-inline: 4px;
}

.cout-password-input-container-desktop img{
    position: absolute;
    width: 4%;
    aspect-ratio: 1;
    right: 2%;
    top: 20%;
    cursor: pointer;
}

.invalid-form::placeholder{
    color: red;
    font-size: 18px;
}

.invalid-form-mobile::placeholder{
    color: red;
    font-size: 9px;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #508B68; 
    border-radius: 50%; 
    width: 25px; 
    height: 25px; 
    animation: spin 2s linear infinite; 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.cout-google-sign-in-button {
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
    padding: 1% 1.5% 1.5% 7%;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: clamp(0.5rem, 1.25vw, 1.25rem);
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 5% 55%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cout-google-sign-in-button:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.cout-google-sign-in-button:active {
    background-color: #eeeeee;
}

.cout-google-sign-in-button:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.cout-google-sign-in-button:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}

#stripe-logo-desktop{
    position: absolute;
    width: 20%;
    right: 0;
    padding-top: 36px;
    top: 0;
}

@media(max-width: 1800px) {
    #stripe-logo-desktop {
        width: 25%;
    }
}

/*/////////////   MOBILE STYLES   ////////////////*/

#logged-in-modal-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    position: fixed;
    bottom: 2%;
    background-color: #2D2D2D;
    z-index: 15;
    align-self: center;
    left: 13%;
    width: 70%;
    border-radius: 15px;
    padding-inline: 2%;
    justify-content: center;
    align-items: center;
    animation: fadeInOut 7s both;
    opacity: 0;
}

#logged-in-modal-mobile p{
    font-family: 'Roboto', sans-serif;
    font-size: 4vw;
    font-weight: 500;
}

#logged-in-modal-mobile img{
    max-width: 10%;
    object-fit: contain;
    margin-left: 5%;
}

#cout-main-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: #101010;
}

#cout-checkout-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    width: 90%;
    align-self: center;
    justify-content: center;
    align-items: center;
}

#cout-info-side-container-mobile{
    background-color: #101010;
    width: 100%;
}

#cout-info-side-container-mobile h2{
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: clamp(0.15rem, 2rem, 4rem);
    color: #508B68;
}

#cout-info-side-container-mobile h3{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.15rem, 1.25rem, 3rem);
    margin-bottom: 2%;
    margin-top: 2%;
    color: var(--text);
}

#cout-info-side-container-mobile form{
    margin-left: 2%;
}

#cout-info-side-container-mobile h4{
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: 5vw;
    margin-bottom: 0;
    text-align: center;
}

#cout-form-names-container-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
}

#cout-form-names-container-mobile div{
    max-width: 49%;
    margin-right: 2%;
}

.cout-form-item-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin-block: 2%;
}

.cout-form-item-container-mobile label{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 4vw;
    margin-block: 1%;
    color: var(--text);
}

.cout-form-item-container-mobile input{
    border: none;
    background-color: #202020;
    padding: 5px;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 4vw;
    border-radius: 5px;
    color: var(--text);
}

.cout-password-input-container-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    position: relative;
}

.cout-password-input-container-mobile input{
    width: 100%;
}

.cout-password-input-container-mobile img{
    position: absolute;
    width: 4%;
    right: 2%;
    top: 25%;
    cursor: pointer;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.cout-google-sign-in-button-mobile {
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s; 
    padding: 2% 4% 2% 10%;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: 3vw;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 10% 55%;
    background-size: 8%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cout-google-sign-in-button-mobile:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.cout-google-sign-in-button-mobile:active {
    background-color: #eeeeee;
}

.cout-google-sign-in-button-mobile:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.cout-google-sign-in-button-mobile:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}

#cout-total-side-container-mobile{
    background-color: #101010;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#cout-total-side-container-mobile h2{
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: clamp(0.15rem, 2rem, 4rem);
    color: var(--text);
}

#cout-items-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
}

#cout-items-total-container-mobile{
    margin-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    max-height: 10%;
}

#cout-items-container-mobile h3{
    font-family: 'Inter', serif;
    font-size: 5vw;
    color: var(--text);
}

.cout-totals-line-container-mobile{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.cout-totals-line-container-mobile h3{
    font-family: 'Inter', serif;
    font-size: 4vw;
    color: var(--text);
}

.cout-totals-line-container-mobile h4{
    font-family: 'Inter', serif;
    font-size: 4vw;
    color: var(--text);
}

.cout-totals-line-container-mobile p{
    font-family: 'Inter', serif;
    color: var(--text);
}

#cout-pay-total-button-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 7%;
    background-color: #508B68;
    border: none;
    position: relative;
    margin-top: 5%;
    margin-bottom: 15%;
    align-self: center;
    color: #FFFFFF;
    border-radius: 50px;
    cursor: pointer;
}

#cout-pay-total-button-mobile p{
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1rem, 1.25vw, 2rem);
}

/*/////////////   PRODUCT ITEM STYLES   /////////////////*/

#cout-product-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    max-height: 5%;
    margin-block: 2%;
}

#cout-product-icon-desktop{
    width: 20%;
    height: auto;
    object-fit: fill;
    border-radius: 5px;
    margin-right: 5%;
}

#cout-product-container-desktop h5{
    font-family: 'Inter', serif;
    color: var(--text);
    font-weight: 500;
    font-size: clamp(0.5rem, 1.5vw, 1.5rem);
    margin-bottom: 0;
    margin-top: 0;
}

#cout-product-container-desktop p{
    font-family: 'Inter', serif;
    color: var(--text);
    font-weight: 400;
    font-size: clamp(0.45rem, 1.5vw, 1.25rem);
    margin-top: 0;
    margin-bottom: 0;
}

#stripe-logo-mobile{
    position: absolute;
    width: 25%;
    right: 0;
    top: 8%;
}

/*////////////////  PRODUCT CHECKOUT MOBILE STYLES*/
#cout-product-container-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    max-height: 10%;
    margin-block: 2%;
}

#cout-product-icon-mobile{
    width: 25%;
    height: auto;
    object-fit: fill;
    border-radius: 10px;
    margin-right: 5%;
}

#cout-product-container-mobile h5{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 5vw;
    margin-bottom: 0;
    margin-top: 0;
    color: var(--text);
}

#cout-product-container-mobile p{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 4vw;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--text);
}