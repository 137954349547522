body{
    background-color: #F2F4F3;
}

.contact-parent-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    background-color: #101010;
    height: 100vh;
}

#home-link-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
    margin-top: 16px;
    text-decoration: none;
}

.contact-parent-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #101010;
    padding-top: 16vh;
    z-index: 1;
    margin-bottom: 15%;
}

#contact-help-form-header{
    font-family: 'Inter', serif;
    font-size: clamp(2.5vw, 2vw, 2.5vw);
    font-weight: 700;
    color: var(--text);
    margin-bottom: 32px;
}

#contact-help-from-header-mobile{
    font-family: 'Inter', serif;
    font-size: clamp(6vw, 7vw, 8vw);
    font-weight: 700;
    color: var(--text);
    margin-bottom: 32px;
}

#contact-help-form{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 55%;
    max-height: 50%;
    position: relative;
}

@media(max-width: 1800px){
    #contact-help-form{
        width: 75%;
    }
}

#contact-help-form-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 90%;
    position: relative;
}

.contact-name-email-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.contact-name-email-container-mobile{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
}   

#contact-name-label, #contact-email-label, #contact-message-label{
    font-family: 'Inter', serif;
    color: var(--text);
    font-size: clamp(1.25vw, 1.25vw, 2.5rem);
    font-weight: 400;
    margin-right: 16px;
}

#contact-name-label-mobile, #contact-email-label-mobile, #contact-message-label-mobile{
    font-family: 'Inter', serif;
    color: var(--text);
    font-size: clamp(3vw, 4vw, 5rem);
    font-weight: 400;
    margin-block: 15px;
}

#contact-email-label{
    padding-left: 32px;
}

#contact-from_name, #contact-from_email{
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    margin-left: 5px;
    border: none;
    border-radius: 5px;
    font-family: 'Inter', serif;
    color: #F5F5F5;
    padding-inline: 16px;
    padding-block: 4px;
    font-size: clamp(1.25vw, 1.25vw, 2.5rem);
    border: 1px solid rgba(255, 255, 255, 0.25);
}

#contact-from_name::placeholder, #contact-from_email::placeholder{
    color: #999999;
}

#contact-from_name-mobile, #contact-from_email-mobile{
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    font-family: 'Inter', serif;
    color: #F5F5F5;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    padding-inline: 5px;
    font-size: clamp(3vw, 4vw, 5rem);
    padding-block: 5px;
    margin-block: 8px;
    box-sizing: border-box;
}

#contact-from_name-mobile::placeholder, #contact-from_email-mobile::placeholder{
    color: #999999;
}

#contact-message{
    border: 1px solid rgba(255, 255, 255, 0.25);
    resize: none;
    border-radius: 10px;
    height: 55%;
    margin-top: 16px;
    padding: 16px;
    box-sizing: border-box;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    font-family: 'Inter', serif;
    font-size: clamp(1.25vw, 1.25vw, 2.5rem);
    color: var(--text);
}

#contact-message::placeholder{
    color: #999999;
}

#contact-message-mobile{
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    resize: none;
    border-radius: 10px;
    min-height: 150px;
    padding: 10px;
    font-family: 'Inter', serif;
    font-size: clamp(3vw, 4vw, 5rem);
}

#contact-message-mobile::placeholder{
    color: #999999;
}

#contact-submit-button{
    width: 15%;
    position: absolute;
    bottom: 10%;
    right: 0%;
    padding-block: 10px;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: clamp(1.25vw, 1.25vw, 2.5rem);
    border-radius: 30px;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    background-color: #508B68;
}

#contact-submit-button-mobile{
    width: 35%;
    right: 0%;
    border: none;
    background-color: #508B68;
    padding-block: 10px;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: clamp(3vw, 4vw, 5rem);
    border-radius: 30px;
    margin-top: 15px;
    margin-left: 65%;
    cursor: pointer;
}