#bannercarousel-container-desktop{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

#bannercarousel-container-mobile{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.banner-hide{
    animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-100%);
    }
}

.banner-show{
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}

#fpBanner0-desktop{
    align-self: center;
    width: 100%;
    object-fit: cover;
    z-index: 5;
}

#fpBanner1-desktop{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 4;
    width: 100%;
}

#fpBanner2-desktop{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 3;
    width: 100%;
}

#fpBanner3-desktop{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 2;
    width: 100%;
}

#fpBanner4-desktop{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 1;
    width: 100%;
}

/*//////////MOBILE STYLES///////////*/

#fpBanner0-mobile{
    align-self: center;
    width: 100%;
    object-fit: cover;
    z-index: 5;
}

#fpBanner1-mobile{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 4;
    width: 100%;
}

#fpBanner2-mobile{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 3;
    width: 100%;
}

#fpBanner3-mobile{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 2;
    width: 100%;
}

#fpBanner4-mobile{
    position: absolute;
    align-self: center;
    object-fit: cover;
    z-index: 1;
    width: 100%;
}