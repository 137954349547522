#footer-container-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 5%;
    background-color: var(--background-color);
    color: var(--text);
    position: relative;
    z-index: 5;
}

#footer-container-desktop p {
    font-family: 'Inter', serif;
    font-size: clamp(0.5rem, 1.5vw, 0.8rem);
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    color: var(--text);
}

#footer-site-map-container-desktop {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-left: 64px;
    margin-right: 64px;
    justify-content: space-between;
    position: relative;
    margin-bottom: 2%;
}

@media(max-width: 1800px) {
    #footer-site-map-container-desktop {
        width: calc(100% - 128px);
    }
}

.footer-site-column-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 25%;
}

.footer-site-column-desktop h5 {
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: clamp(0.5rem, 1.5vw, 0.9rem);
    margin-bottom: 3%;
    width: 100%;
    text-align: left;
    padding: 0;
    margin-inline: 0;
}

.footer-site-column-desktop a {
    color: #508B68;
    font-family: 'Inter', serif;
    font-size: clamp(0.25rem, 1.5vw, 0.75rem);
    text-align: left;
    width: 100%;
    margin-block:4px;
    padding: 0;
    margin-inline: 0;
}

#footer-container-mobile {
    margin-left: 16px;
    padding-top: 10%;
    padding-bottom: 15%;
}

#footer-container-mobile p {
    font-family: 'Inter', serif;
    font-size: 2vw;
    color: var(--text);
}

.footer-site-column-address-desktop {
    border-left: 1px solid #508B68;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 30%;
}

.footer-site-column-address-desktop img{
    width: 45%;
    object-fit: contain;
    margin-inline: 16px;
}

.footer-site-column-address-desktop h5 {
    font-weight: 500;
    font-family: 'Inter', serif;
    font-size: clamp(0.5rem, 1.5vw, 0.9rem);
    margin: 0;
    padding: 0;
}

.footer-site-column-address-desktop address {
    font-weight: 400;
    font-family: 'Inter', serif;
    font-size: clamp(0.25rem, 1.5vw, 0.75rem);
    margin: 0;
    padding: 0;
}