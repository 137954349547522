#sf-signin-button{
    all: unset;
    background-color: #F5F5F5;
    border-radius: 50px;
    margin-left: 16px;
    text-decoration: none;
    pointer-events: all;
    cursor: pointer;
    transition: opacity 1s;
}

#sf-signin-button p{
    margin: 0;
    padding: 8px 32px;
    font-weight: 700;
    background: var(--alt-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font);
    font-weight: 700;
}

#sf-pfpImage-desktop{
    padding-right: 8px;
    width: 5%;
    cursor: pointer;
}

@media(max-width: 1800px) {
    #cmpp-buy-container-desktop{
        width: 10%;
    }
}

#sf-shoppingcart-img-desktop{
    width: 100%;
    object-fit: contain;
}

#sf-profile-modal{ 
    position: absolute;
    display: flex;
    flex: 1;
    flex-direction: column;
    z-index: 0;
    width: 15%;
    top: 75%;
    right: -1px;
    margin-right: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-block: 16px;
    background-color: var(--background-color);
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    border-left: 1px solid rgba(255, 255, 255, 0.25);
}

#sf-profile-modal *{
    font-family: 'Inter', serif;
    font-weight: 400;
}

#sf-viewprofilelink{
    border: 1px solid transparent;
    border-radius: 50px;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    margin-left: 16px;
    margin-right: 16px;
    text-decoration: none;
    transition: border-color 1s;
    padding-block: 10px;
    padding-inline: 15px;
    margin-bottom: 8px;
}

#sf-viewprofilelink p{
    color: #508b68;
    padding: 0;
    margin: 0;
    text-align: center;
}

#sf-signout-button{
    all: unset;
    background-color: #F5F5F5;
    border-radius: 50px;
    padding-block: 10px;
    padding-inline: 15px;
    margin-left: 16px;
    margin-right: 16px;
}

#sf-signout-button p{
    color: #508b68;
    text-align: center;
}

#sf-main-desktop{
    position: relative;
    background-color: #101010;
    margin-top: 8vh;
    padding: 0;
}

#sf-featured-container-desktop{
    width: 60%;
    padding: 0;
    margin-block: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.25)
}

@media(max-width: 1800px) {
    #sf-featured-container-desktop{
        width: calc(100% - 128px);
        margin-left: 64px;
        margin-right: 64px;
    }
}

#sf-gradient-desktop{
    position: absolute;
    width: 100%;
    top: -45%;
    z-index: 0;
    align-self: center;
}

@media(max-width: 1800px) {
    #sf-gradient-desktop{
        position: absolute;
        width: 100%;
        top: -15%;
        z-index: 0;
    }
}

#sf-products-container-desktop{
    width: 60%;
    z-index: 1;
    position: relative;
}

@media(max-width: 1800px) {
    #sf-products-container-desktop{
        width: calc(100% - 128px);
        margin-left: 64px;
        margin-right: 64px;
    }
}

#sf-products-header-container-desktop{
    width: 100%;
    z-index: 1;
    position: relative;
    margin-top: 32px;
}

#sf-products-header-container-desktop h2{
    text-align: center;
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1.75rem, 1.5vw, 2.75rem);
    margin: 0;
    padding: 0;
}

#sf-product-items-container-desktop{
    width: 100%;
    height: fit-content;
    position: relative;
    margin-block: 16px;
    padding-bottom: 64px;
}

/*////////// MOBILE STYLES /////////////*/

.sf-store-nav-mobile{
    background-color: #101010;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-block: 16px;
    padding-inline: 8px;
    position: fixed;
    z-index: 10;
    top: 0;
}

#nav-logo-mobile{
    width: 70%;
}

#sf-signin-button-mobile{
    all: unset;
    background-color: #F5F5F5;
    border-radius: 50px;
    margin-left: 16px;
    text-decoration: none;
    pointer-events: all;
    cursor: pointer;
    
}

#sf-signin-button-mobile p{
    margin: 0;
    padding: 4px 16px;
    box-sizing: border-box;
    font-weight: 700;
    background: var(--alt-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font);
    text-align: center;
}

#sf-pfpImage-mobile{
    padding-right: 8px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
}

#sf-shoppingcart-img-mobile{
    width: 20%;
    object-fit: contain;
}

#sf-profile-modal-mobile{
    position:absolute;
    top: 100%;
    margin-top: 8px;
    background-color: #101010;
    padding-inline: 16px;
    padding-bottom: 16px;
    z-index: 15;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 100%;
    margin-right: 0;
    right: -5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sf-view-link-mobile{
    border: 1px solid transparent;
    border-radius: 50px;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    margin-left: 16px;
    margin-right: 16px;
    text-decoration: none;
    transition: border-color 1s;
    padding-block: 10px;
    padding-inline: 15px;
    margin-bottom: 8px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
}

#sf-view-link-mobile p{
    color: #508b68;
    padding: 0;
    margin: 0;
    text-align: center; 
}

#sf-signout-button-mobile{
    all: unset;
    background-color: #F5F5F5;
    border-radius: 50px;
    padding-block: 10px;
    padding-inline: 15px;
    width: 75%;
}

#sf-signout-button-mobile p{
    color: #508b68;
    text-align: center;
    padding: 0;
    margin: 0;
    text-align: center;
}

#sf-main-mobile{
    position: relative;
    background-color: #101010;
    margin-top: 16vh;
    padding: 0;
}

#sf-featured-container-mobile{
    width: 90%;
    padding: 0;
    margin-block: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.25)
}

#sf-gradient-mobile{
    position: absolute;
    width: 150%;
    top: -10%;
    left: 0%;
    z-index: 0;
    align-self: center;
}

#sf-products-container-mobile{
    width: 90%;
    z-index: 1;
    position: relative;
}

#sf-products-header-container-mobile{
    width: 100%;
    z-index: 1;
    position: relative;
    margin-top: 32px;
}

#sf-products-header-container-mobile h2{
    text-align: center;
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1.5rem, 1.5vw, 2.5rem);
    margin: 0;
    padding: 0;
}

#sf-product-items-container-mobile{
    width: 100%;
    height: fit-content;
    position: relative;
    margin-block: 16px;
    padding-bottom: 64px;
}