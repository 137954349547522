/*/////////     ERROR HANDLING    /////////*/
.error-text{
    color: red;
    margin-top: 0;
    margin-bottom: 0;
}

/*/////////////////////////////////////////*/
#store-link-container{
    display: flex;
    flex-direction: row;
    flex: 1;
    max-width: 5%;
    justify-content: flex-end;
}

#store-icon-desktop{
    width: 45%;
    padding-right: 32px;
}

#signOut-button{
    border: none;
    background-color: #508B68;
    border-radius: 50px;
    padding-inline: 1%;
    cursor: pointer;
}

#signOut-button p{
    font-family: 'Roboto', sans-serif;
    font-size: 0.65vw;
    color: #FFFFFF;
    margin-block: 15%;
}

#profile-main-desktop{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    background: radial-gradient(circle, #202020 0%, #101010 50%);
    overflow-y: scroll;
}

/*////////////  FAILED LOAD STYLES  ///////////////*/

#failedLoad-container-desktop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#failedLoad-container-desktop p{
    color: var(--text);
    font-weight: 700;
    font-family: 'Inter', serif;
    font-size: clamp(0.5rem, 2vw, 4rem);
    padding: 0;
    margin: 0 0 16px 0;
}

#failedLoad-container-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#failedLoad-container-mobile p{
    color: var(--text);
    font-weight: 700;
    font-family: 'Inter', serif;
    font-size: 1.5rem;
    text-align: center;
    padding: 0;
    margin: 0 0 16px 0;
}

/*////////////  NOT LOGGED IN STYLES    ////////////*/

#profile-login-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

#login-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 25%;
    font-family: 'Roboto', sans-serif;
    background-color: #101010;
    padding: 32px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

@media(max-width: 1800px){
    #login-container-desktop{
        width: 40%;
    }
}

#login-header-desktop{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#login-header-desktop img{
    width: 45%;
    object-fit: contain;
    margin-left: -32px;
}

#login-header-desktop h1{
    font-weight: 700;
    font-size: clamp(1.5vw, 1.8vw, 3vw);
    font-family: 'Inter', serif;
    margin: 0;
    padding: 0;
    color: var(--text);
    margin-left: -16px;
}

@media(max-width: 1800px){
    #login-header-desktop h1{
        font-size: clamp(1.5vw, 3vw, 3vw);
    }
}

#login-form-desktop{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-inline: 32px;
    box-sizing: border-box;
}

#login-form-desktop label{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.75vw, 1vw, 2.5vw);
    color: var(--text);
}

@media(max-width: 1800px){
    #login-form-desktop label{
        font-size: clamp(1.5vw, 1vw, 3vw);
    }
}

#login-form-desktop input{
    border: none;
    background-color: #202020;
    padding-block: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    border-radius: 5px;
    font-family: 'Inter', serif;
    padding-inline: 16px;
    font-size: clamp(0.5rem, 0.75vw, 2rem);
    color: var(--text);
}

@media(max-width: 1800px){
    #login-form-desktop input{
        font-size: clamp(1.5vw, 1vw, 3vw);
    }
}

#login-form-desktop input::placeholder{
    color: #2D2D2D;
}

#login-button-desktop{
    background-color: #508B68;
    border: none;
    border-radius: 50px;
    align-self: flex-end;
    padding-block: 8px;
    padding-inline: 32px;
    margin-top: 16px;
    margin-right: 32px;
    cursor: pointer;
}

#login-button-desktop p{
    color: #FFFFFF;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(0.75vw, 1vw, 2.5vw);
    margin: 0;
    padding: 0;
}

@media(max-width: 1800px){
    #login-button-desktop p{
        font-size: clamp(1.5vw, 1vw, 3vw);
    }
}

#login-container-desktop h3{
    align-self: center;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.75vw, 1.5vw, 2.5vw);
    color: var(--text);
}

.google-sign-in-button-profile {
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
    padding: 3% 3.5% 3.5% 10%;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: clamp(0.75vw, 1.5vw, 2.5vw);
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 5% 55%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
    align-self: center;
}

.google-sign-in-button-profile:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button-profile:active {
    background-color: #eeeeee;
}

.google-sign-in-button-profile:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.google-sign-in-button-profile:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}

#create-account-text-desktop{
    font-family: 'Inter', serif;
    font-size: clamp(0.25vw, 1vw, 2vw);
    font-weight: 400;
    position: absolute;
    bottom: 0;
    text-decoration: none;
    color: var(--text);
}

/*///////// NOT LOGGED IN MOBILE STYLES ///////*/

#store-nav-links-mobile{
    height: 100%;
    max-width: 30%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 8px;
}

#store-link-container-mobile{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    max-width: 50%;
}

#store-icon-mobile{
    max-width: 70%;
    object-fit: contain;
}

#profile-main-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    background: radial-gradient(circle, #202020 0%, #101010 100%);
}

#profile-login-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

#login-container-mobile{
    display: flex;
    flex-direction: column;
    width: 85%;
    font-family: 'Inter', serif;
    background-color: #101010;
    padding: 5%;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

#login-container-mobile h3{
    align-self: center;
    font-weight: 400;
    font-size: 10vw;
    color: var(--text);
}

#login-header-mobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
}

#login-header-mobile img{
    width: 40%;
    object-fit: contain;
}

#login-header-mobile h1{
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    color: var(--text);
}

#login-form-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 32px;
}

#login-form-mobile label{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 1rem;
    color: var(--text);
}

#login-form-mobile input{
    border: none;
    background-color: #202020;
    padding: 8px 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    border-radius: 5px;
    font-size: 1rem;
    color: var(--text);
}

#login-form-mobile input::placeholder{
    color: #2D2D2D;
}

#login-container-mobile h3{
    align-self: center;
    font-weight: 400;
    font-size: 4vw;
    margin-top: 16px;
    margin-bottom: 16px;
    color: var(--text);
}

#login-button-mobile{
    background-color: #508B68;
    border: none;
    border-radius: 50px;
    width: 30%;
    align-self: flex-end;
    padding-block: 2%;
    padding-inline: 2%;
    margin-top: 5%;
    cursor: pointer;
}

#login-button-mobile p{
    color: #FFFFFF;
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
}

.google-sign-in-button-mobile-profile {
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
    padding: 3% 3.5% 3.5% 10%;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: 5vw;
    font-weight: 400;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 5% 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-self: center;
}

.google-sign-in-button-mobile-profile:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button-mobile-profile:active {
    background-color: #eeeeee;
}

.google-sign-in-button-mobile-profile:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.google-sign-in-button-mobile-profile:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}

#create-account-text-mobile{
    font-family: 'Roboto';
    font-size: 4vw;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    text-decoration: none;
    color: #000000;
}

/*////////////  LOGGED IN STYLES    ////////////*/

#profile-scroll-container-desktop{
    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', serif;
    padding-bottom: 5%;
    background-color: var(--background-color);
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding-inline: 32px;
    border-radius: 25px;
    margin-bottom: 64px;
}

@media(max-width: 1800px){
    #profile-scroll-container-desktop{
        width: 50%;
    }
}

.profile-information-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.profile-information-container-desktop h2{
    font-weight: 500;
    font-size: clamp(0.5rem, 2rem, 4rem);
    color: var(--text);
}

#profile-image-desktop{
    width: 35%;
    margin-bottom: 64px;
    object-fit: contain;
}

#profile-information-individual-item-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

#profile-information-individual-item-desktop h4{
    font-weight: 700;
    margin: 0;
    padding: 0;
    margin-bottom: 32px;
    font-size: clamp(0.25rem, 1.25rem, 2rem);
    color: var(--text);
    width: 100%;
}

#profile-information-individual-item-desktop p{
    margin: 0;
    margin-bottom: 32px;
    color: var(--text);
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    padding: 0;
}

#profile-change-button-desktop{
    border: 1px solid transparent;
    border-radius: 50px;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    margin-bottom: 32px;
    text-decoration: none;
    transition: border-color 1s;
    cursor: pointer;
}

#profile-change-button-desktop p{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1rem, 2rem);
    color:#FFFFFF;
    margin: 0;
    padding-block: 8px;
    padding-inline: 8px;
}

#order-list-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 105%;
    max-height: 25%;
    overflow-y: scroll;
}

#order-list-container-desktop h3{
    font-weight: 500;
    font-size: clamp(1rem, 1.5rem, 3rem);
    margin-top: 0;
    color: var(--text);
}

#order-list-item-desktop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#order-list-item-desktop p{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    margin-block: 3%;
    color: var(--text);
}

#order-list-item-desktop button{
    background-color: #F5F5F5;
    border: none;
    color: #508B68;
    border-radius: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: clamp(0.25rem, 1rem, 1.5rem);
    padding-inline: 16px;
    padding-block: 8px;
    cursor: pointer;
}

#downloadable-parent-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

#downloadable-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 32px;
}

#downloadable-container-desktop h4{
    font-size: clamp(0.25rem, 1.25rem, 2rem);
    font-weight: 400;
    padding: 0;
    margin: 0;
}

#downloadable-parent-container-desktop img{
    width: 8%;
    margin-right: 16px;
    object-fit: contain;
}

#downloadable-container-desktop a{
    color: #508B68;
    text-decoration: underline;
    cursor: pointer;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    margin-left: 8px;
}

#stripe-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

#stripe-container-desktop h3{
    color: var(--text);
    font-weight: 400;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
}

#stripe-container-desktop button{
    width: 25%;
    border: none;
    background-color: #6772e5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    padding-block: 1%;
    cursor: pointer;
}

@media(max-width: 1800px){
    #stripe-container-desktop button{
        width: 15%;
    }
}

#stripe-container-desktop img{
    width: 65%;
    object-fit: fill;
}

/*///////// LOGGED IN MOBILE STYLES //////////*/

#signOut-button-mobile{
    border: none;
    background-color: #508B68;
    border-radius: 50px;
    padding-inline: 5%;
    min-width: 60%;
    align-self: center;
}

#signOut-button-mobile p{
    font-family: 'Roboto', sans-serif;
    font-size: 3vw;
    color: #FFFFFF;
    margin-block: 15%;
}

#profile-scroll-container-mobile{
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
}

.profile-information-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 32px;
    background-color: #101010;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    padding-inline: 16px;
    box-sizing: border-box;
    padding-bottom: 32px;
}

.profile-information-container-mobile h2{
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--text);
}

#profile-image-mobile{
    width: 40%;
    margin-bottom: 32px;
}

#profile-information-individual-item-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-block: 16px;
}

#profile-information-individual-item-mobile h4{
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
    margin-bottom: 8px;
    width: 100%;
}

#profile-information-individual-item-mobile p{
    margin: 0;
    font-size: 1.25rem;
    color: var(--text);
}

#profile-change-button-mobile{
    border: 1px solid transparent;
    border-radius: 50px;
    background:
        linear-gradient(to right, #101010, #101010),
        var(--alt-gradient);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    text-decoration: none;
    transition: border-color 1s;
    cursor: pointer;
}

#profile-change-button-mobile p{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color:#FFFFFF;
    margin: 0;
    padding-block: 15%;
}

#order-list-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

#order-list-container-mobile h3{
    font-weight: 500;
    font-size: 1.25rem;
    margin-top: 0;
    color: var(--text);
}

#order-list-item-mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

#order-list-item-mobile p{
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: 4vw;
    color: var(--text);
}

#order-list-item-mobile button{
    background-color: #F5F5F5;
    border: none;
    color: #508B68;
    border-radius: 50px;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: 1rem;
    padding-inline: 16px;
    padding-block: 4px;
    cursor: pointer;
}

#downloadable-parent-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

#downloadable-container-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;;
}

#downloadable-container-mobile img{
    width: 14%;
    margin-right: 8px;
}

#downloadable-container-mobile h4{
    font-size: 4vw;
    color: var(--text);
}

#downloadable-container-mobile a{
    color: #508B68;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 8px;
}

#stripe-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--text);
    font-size: 0.9rem;
}

#stripe-container-mobile button{
    width: 80%;
    border: none;
    background-color: #6772e5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding-block: 8px;
    cursor: pointer;
    margin-bottom: 32px;
}

#stripe-container-mobile img{
    width: 20%;
    object-fit: contain;
}

/*////////  CHANGE INFO MODAL STYLES    /////////*/

#changeInfo-main-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: rgba(0,0,0,0.25);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    justify-content: center;
    backdrop-filter: blur(5px);
    align-items: center;
}

#changeInfo-container-desktop{
    display: flex;
    flex-direction: column;
    background-color: #202020;
    width: 35%;
    border-radius: 25px;
    padding-inline: 32px;
    padding-block: 32px;
    box-sizing: border-box;
    position: relative;
}

@media(max-width: 1800px){
    #changeInfo-container-desktop{
        width: 50%;
    }
}

#close-icon-info-desktop{
    position: absolute;
    width: 3%;
    object-fit: fill;
    top: 4%;
    right: 2%;
    cursor: pointer;
}

#changeInfo-container-desktop h3{
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(0.75rem, 1.5rem, 2.25rem);
    margin-top: 0;
    margin-bottom: 32px;
    color: var(--text);
}

#changeInfo-container-desktop form{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-inline: 8px;
    box-sizing: border-box;
}

#changeInfo-container-desktop label{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    color: var(--text);
    margin-bottom: 16px;
}

#changeInfo-container-desktop input{
    margin-bottom: 32px;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    background-color: #2D2D2D;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    color: var(--text);
}

#changeInfo-container-desktop button{
    border: none;
    background-color: #F5F5F5;
    border-radius: 50px;
    padding-block: 8px;
    padding-inline: 16px;
    box-sizing: border-box;
    align-self: flex-end;
    cursor: pointer;
}

#changeInfo-container-desktop button p{
    margin: 0;
    padding: 0;
    color: #508B68;
    font-family: 'Inter', serif;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    font-weight: 700;
}


/*////////  CHANGE INFO MOBILE STYLES   ////////*/

#changeInfo-main-mobile{
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.15);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 15;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

#changeInfo-container-mobile{
    display: flex;
    flex-direction: column;
    background-color: #202020;
    width: 90%;
    border-radius: 15px;
    padding-inline: 16px;
    padding-block: 16px;
    box-sizing: border-box;
    position: relative;
}

#close-icon-info-mobile{
    position: absolute;
    width: 6%;
    object-fit: fill;
    top: 4%;
    right: 4%;
    cursor: pointer;
}

#changeInfo-container-mobile h3{
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: 1.75rem;
    margin-block: 16px;
    color: var(--text);
}

#changeInfo-container-mobile form{
    display: flex;
    flex-direction: column;
    flex: 1;
}

#changeInfo-container-mobile label{
    font-family: 'Inter', serif;
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--text);
}

#changeInfo-container-mobile input{
    margin-block: 5%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.15rem;
    background-color: #2D2D2D;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    color: var(--text);
}

#changeInfo-container-mobile button{
    border: none;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: 1.15rem;
    background-color: #F5F5F5;
    color: #508B68;
    border-radius: 50px;
    padding: 8px 16px;
    align-self: flex-end;
    cursor: pointer;
    margin-top: 16px;
}

#changeInfo-container-mobile button p{
    margin: 0;
    padding: 0;
}

/*////////  ORDER INFO STYLES   ////////*/

#order-information-container-desktop{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #202020;
    padding: 32px;
    width: 30%;
    box-sizing: border-box;
    border-radius: 25px;
    margin-top: -6vh;
    backdrop-filter: blur(10px);
}

@media(max-width: 1800px){
    #order-information-container-desktop{
        width: 60%;
    }
}

#order-information-container-desktop img{
    width: 3.5%;
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
}

#order-information-container-desktop h2{
    font-family: 'Inter', serif;
    font-size: clamp(0.5rem, 2rem, 4rem);
    font-weight: 700;
    margin-bottom: 16px;
    color: var(--text);
}

#order-information-container-desktop h3{
    font-family: 'Roboto', sans-serif;
    font-size: clamp(0.75rem, 1.5rem, 2.25rem);
    font-weight: 700;
    margin-block: 8px;
    color: var(--text);
}

#license-keys-header-desktop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#license-keys-header-desktop button{
    background-color: #F5F5F5;
    border: none;
    color: #508B68;
    border-radius: 50px;
    padding-inline: 16px;
    padding-block: 8px;
    font-family: 'Inter', serif;
    font-size: clamp(0.25rem, 1rem, 1.5rem);
    cursor: pointer;
}

#license-keys-container-desktop{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 250px;
    margin-top: 32px;
    padding-inline: 16px;
    box-sizing: border-box;
}

.key-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.key-container-desktop p{
    position: relative;
    z-index: 0;
    pointer-events:none;
    font-weight: 400;
    margin-left: 8px;
    font-family: 'Inter', serif;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    color: var(--text);
}

.key-cover-desktop{
    background: linear-gradient(to right, rgba(242, 244, 243, 0) 20%, #202020 65%);
    width: 70%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    pointer-events: none;
}

.key-container-desktop button{
    background-color: #F5F5F5;
    border: none;
    color: #508B68;
    z-index: 5;
    font-family: 'Inter', serif;
    font-size: clamp(0.25rem, 1rem, 2rem);
    font-weight: 400;
    border-radius: 50px;
    padding-inline: 16px;
    padding-block: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#copy-error-bg-desktop{
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(5px);
    z-index: 10;
}

#copy-error-container-desktop{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: #202020;
    width: 25%;
    height: fit-content;
    padding: 1%;
    border-radius: 25px;
    box-shadow: 0px 20px 55px 10px rgba(0, 0, 0, .15);
}

@media(max-width: 1800px){
    #copy-error-container-desktop{
        width: 45%;
    }
}

#copy-error-close-desktop{
    max-width: 3.5%;
    align-self: flex-end;
    cursor: pointer;
}

#copy-error-container-desktop img{
    width: 20%;
}

@media(max-width: 1800px){
    #copy-error-container-desktop img{
        width: 35%;
    }
}

#copy-error-container-desktop h4{
    width: 90%;
    text-align: center;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1.25rem, 2rem);
    margin-bottom: 0;
    color: var(--text);
}

#copy-error-container-desktop p{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1rem, 2rem);
    align-self: center;
    text-align: center;
    color: var(--text);
}

/*///////    ORDER INFO MOBILE STYLES    ///////*/

#order-information-container-mobile{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #202020;
    padding: 16px;
    width: 90%;
    border-radius: 10px;
    box-sizing: border-box;
}

#order-information-container-mobile img{
    width: 5%;
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
}

#order-information-container-mobile h2{
    font-family: 'Inter', serif;
    font-size: 1.25rem;
    font-weight: 500;
    margin-block: 16px;
    padding: 0;
    color: var(--text);
}

#order-information-container-mobile h3{
    font-family: 'Inter', serif;
    font-size: 1rem;
    font-weight: 700;
    margin-block: 4px;
    color: var(--text);
}

#license-keys-header-mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

#license-keys-header-mobile button{
    background-color: #f5f5f5;
    border: none;
    color: #508B68;
    border-radius: 50px;
    padding-inline: 4%;
    padding-block: 2%;
    font-family: 'Inter', serif;
    font-size: 0.75rem;
    cursor: pointer;
}

#license-keys-container-mobile{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 200px;
    padding-inline: 8px;
    margin-top: 16px;
}

.key-container-mobile{
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.key-container-mobile p{
    position: relative;
    z-index: 0;
    pointer-events:none;
    font-weight: 500;
    margin-left: 2%;
    font-family: 'Inter', serif;
    font-size: 1rem;
    color: var(--text);
}

.key-cover-mobile{
    background: linear-gradient(to right, rgba(242, 244, 243, 0) 40%, #202020 65%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    pointer-events: none;
}

.key-container-mobile button{
    background-color: #F5F5F5;
    border: none;
    color: #508B68;
    z-index: 5;
    font-family: 'Inter', serif;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#copy-error-bg-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(5px);
    z-index: 10;
}

#copy-error-container-mobile{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: #202020;
    width: 90%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 25px;
}

#copy-error-close-mobile{
    max-width: 8%;
    object-fit: contain;
    align-self: flex-end;
    cursor: pointer;
}

#copy-error-container-mobile img{
    width: 35%;
}

#copy-error-container-mobile h4{
    width: 100%;
    text-align: center;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0;
}

#copy-error-container-mobile p{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    width: 100%;
    align-self: center;
    text-align: center;
    color: var(--text);
}

/*////////////      WARNING MODAL DESKTOP STYLES    /////////////*/

#warning-modal-parent-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

#warning-modal-container-desktop{
    display: flex;
    flex-direction: column;
    background-color: #202020;
    width: 40%;
    border-radius: 15px;
    padding: 32px;
    padding-block: 32px;
    box-sizing: border-box;
    position: relative;
}

@media(max-width: 1800px){
    #warning-modal-container-desktop{
        width: 60%;
    }
}

#warning-modal-container-desktop img{
    position: absolute;
    width: 2.5%;
    right: 2%;
    cursor: pointer;
}

#warning-modal-container-desktop h2{
    font-family: 'Inter', serif;
    font-weight: 700;
    color: var(--text);
    font-size: clamp(0.5rem, 2rem, 4rem);
}

#warning-modal-container-desktop p{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    color: var(--text);
}

#warning-modal-container-desktop button{
    border: none;
    background-color: #F5F5F5;
    color: #508B68;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: clamp(0.25rem, 1.15rem, 2rem);
    padding-block: 8px;
    padding-inline: 16px;
    width: fit-content;
    border-radius: 50px;
    margin-top: 16px;
    cursor: pointer;
    align-self: flex-end;
}

/*/////////////     WARNING MODAL MOBILE STLYES     ///////////////*/
#warning-modal-parent-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

#warning-modal-container-mobile{
    display: flex;
    flex-direction: column;
    background-color: #202020;
    width: 90%;
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
}

#warning-modal-container-mobile img{
    position: absolute;
    width: 6vw;
    right: 5%;
    top: 2%;
    cursor: pointer;
}

#warning-modal-container-mobile h2{
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: 1.25rem;
    padding-top: 8px;
    color: var(--text);
}

#warning-modal-container-mobile p{
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 1rem;
    color: var(--text);
}

#warning-modal-container-mobile button{
    border: none;
    background-color: #F5F5F5;
    color: #508B68;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: 3.75vw;
    padding-block: 8px;
    padding-inline: 16px;
    border-radius: 50px;
    align-self: flex-end;
    margin-top: 16px;
    cursor: pointer;
}