/* Base Styles (Desktop) */
.freetrial-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: var(--background-color);
}

.content-box {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 40px;
    width: 30%;
    text-align: left;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: 1px solid rgba(255,255,255, 0.25);
}

.logo {
    width: 25%;
    object-fit: contain;
    aspect-ratio: 1;
    margin-bottom: 20px;
}

@media (max-width: 1800px) {
    .content-box {
        width: 60%;
    }
}

.success-message h2 {
    color: var(--text);
    font-size: clamp(1.5rem, 5vw, 2rem);
    margin-bottom: 20px;
    font-family: 'Inter', serif;
}

.email-input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    margin-bottom: 16px;
    border: none;
    border-radius: 5px;
    font-size: clamp(0.75rem, 4vw, 1.25rem);
    font-family: 'Inter', serif;
    background-color: #202020;
    color: var(--text);
}

.submit-button {
    background-color: #3390c4;
    color: var(--text);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: clamp(0.75rem, 4vw, 1.25rem);
    cursor: pointer;
    width: 100%;
    font-family: 'Inter', serif;
}

.submit-button:hover {
    background-color: #005f9c;
}

.success-message {
    color: #4caf50;
}

#recaptcha{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.error{
    color: red;
}

a{
    color:#508b68;
}

.header-desktop {
    display: flex;
    flex-direction: row;
    text-align: left;
    color: var(--text);
}

.header-text-desktop {
    padding-left: 5%;
    font-family: 'Inter', serif;
    color: var(--text);
}

.form{
    color: var(--text);
}

/* Mobile-specific Styles */
@media (max-width: 700px) {
    .freetrial-container {
        width: 100%;
        height: 100vh;
        background-color: var(--background-color);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20vh;
    }

    .content-box {
        padding: 16px;
        width: 90%;
        box-sizing: border-box;
    }

    .logo {
        width: 50%;
    }

    .header-mobile {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-family: 'Inter', serif;
    }

    .header-text-mobile {
        padding-top: 10px;
        font-family: 'Inter', serif;
        color: var(--text);
    }

    .form-mobile{
        text-align: center;
        font-family: 'Inter', serif;
        color: var(--text);
    }

    .success-message{
        text-align: center;
    }
}
