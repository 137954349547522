#main-container{
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    padding-top: 6vh;
    background-color: #F2F4F3;
    overflow-y: scroll;
}

.title-container-desktop{
    position: relative;
    width: 100%;
    height: 50%;
}

#title-container-bg-desktop{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 75%;
    background-color: #CDDBD3;
    position: absolute;
    z-index: 0;
}

#slanted-artwork{
    height: 100%;
    width: 100%;
    display: block;
}

.text-image-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 45%;
    z-index: 4;
    margin-left: auto;
    margin-right: auto;
}

.text-image-container-desktop h1{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    z-index: 5;
    color: #F2F4F3;
    font-size: 3vw;
    padding-left: 5%;
    padding-bottom: 15%;
    min-width: 45%;
}

.text-image-container-desktop img{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    z-index: 5;
    max-width: 60%;
    height: auto;
    margin-left: -10%;
}

.main-text-container-desktop{
    width: 50%;
}

.section-container-desktop{
    padding-block: 2%;
}

.section-container-desktop h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.25vw;
}

.section-container-desktop p{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1vw;
    text-align: justify;
}

.split-container-desktop{
    display: flex;
    flex-direction: row;
    flex: 1;
}

.image-split-desktop{
    max-width: 40%;
}

/*///////   Mobile Styles   ///////*/

.title-container-mobile{
    position: relative;
    width: 100%;
    height: 50%;
}

#title-container-bg-mobile{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 75%;
    background-color: #CDDBD3;
    position: absolute;
    z-index: 0;
}

.text-image-container-mobile{
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 90%;
    z-index: 4;
    margin-left: auto;
    margin-right: auto;
}

.text-image-container-mobile h1{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    z-index: 5;
    color: #F2F4F3;
    font-size: 6vw;
    padding-left: 5%;
    padding-bottom: 15%;
    min-width: 45%;
}

.text-image-container-mobile img{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    z-index: 5;
    max-width: 60%;
    height: auto;
    margin-left: -10%;
}

.main-text-container-mobile{
    width: 90%;
}

.section-container-mobile{
    padding-block: 2%;
    width: 100%;
}

.section-container-mobile h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 5vw;
}

.section-container-mobile p{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 3.5vw;
    text-align: justify;
}

.split-container-mobile{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.image-split-mobile{
    max-width: 60%;
    height: auto;
    background-color: red;
}