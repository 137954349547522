#processing-container-desktop{
    position: absolute;
    background-color: #101010;
    z-index: 15;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#processing-container-desktop img{
    width: 15%;
    object-fit: contain;
    margin: 0;
    margin-bottom: 16px;
}

#processing-container-desktop h1{
    margin: 0;
    font-family: 'Inter', serif;
    font-weight: 700;
    font-size: clamp(1.5rem, 3vw, 3rem);
    color: var(--text);
}

#orderConfirm-main-desktop{
    padding-top: 6vh;
}

#order-header-container-desktop{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 128px;
    font-family: 'Inter', serif;
    color: var(--text);
}

#order-header-container-desktop h1{
    margin-bottom: 1%;
    font-size: clamp(1.5rem, 3vw, 3rem);
    color: var(--text);
}

#order-header-container-desktop h2{
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    font-size: clamp(1.25rem, 2.5vw, 2.5rem);
}

#order-header-container-desktop h3{
    font-weight: 500;
    margin-bottom: 0;
    font-size: clamp(1rem, 2vw, 2rem);
}

#order-header-container-desktop li{
    font-size: clamp(0.75rem, 1.5vw, 1.5rem);
    margin-block: 16px;
    font-weight: 400;
}

#order-header-container-desktop ol{
    margin-left: 16px;
    font-weight: 400;
}

#order-header-container-desktop ul{
    list-style-type: square;
    font-weight: 400;
}

/*///////////////   MOBILE STYLES   ///////////////*/
#processing-container-mobile{
    position: absolute;
    background-color: #101010;
    z-index: 15;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#processing-container-mobile img{
    width: 35%;
    object-fit: contain;
    margin: 0;
    margin-bottom: 16px;
}

#processing-container-mobile h1{
    margin: 0;
    font-family: 'Inter', serif;
    font-weight: 600;
    font-size: 6vw;
    color: var(--text);
}

#orderConfirm-main-mobile{
    padding-top: 8vh;
}

#order-header-container-mobile{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    font-family: 'Inter', serif;
    width: 90%;
    color: var(--text);
}

#order-header-container-mobile h1{
    margin-bottom: 16px;
    font-weight: 700;
    font-size: clamp(1.5rem, 3vw, 3rem);
}

#order-header-container-mobile h2{
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    font-size: clamp(1.25rem, 2.5vw, 2.5rem);
}

#order-header-container-mobile h3{
    font-weight: 700;
    margin-bottom: 0;
    font-size: clamp(1rem, 2vw, 2rem);
}

#order-header-container-mobile li{
    font-size: clamp(0.9rem, 1.5vw, 1.5rem);
    margin-block: 8px;
    font-weight: 400;
}

#order-header-container-mobile ul{
    list-style-type: square;
    font-weight: 400;
}

#order-header-container-mobile ol{
    font-weight: 400;
}